import Resume from "./components/resume"

function App() {
  return (
    <>
      <Resume></Resume>
    </>
  );
}

export default App;
